$(document).foundation();
Foundation.Abide.defaults.patterns['phone'] = /(^(\+?\-? *[0-9]+)([,0-9 ]*)([0-9 ])*$)|(^ *$)/;

//added the gray overlay outside menu
if (window.matchMedia("(min-width: 765px)").matches) {
    $(".top-nav__links-item_tools").hover(function () {
        var docHeight = $(document).height();
        $("#overlay")
            .height(docHeight - 100)
            .css({
                'display': 'block',
                'margin-top': 100,
                'z-index': '15'
            });
    }, function () {
        $("#overlay").hide();
    });
}


//logic of mobile menu
$('.top-nav__expand').on('click', function () {
    if ($(this).hasClass('is-active')) {
        $(this).text('+');
        $(this).removeClass('is-active');
        $(this).next('.menu-level').hide();
        $(this).parent().parent().find('.menu-level').hide();
        $(this).parent().parent().find('.top-nav__expand').text('+');
    } else {
        $(this).parent().parent().find('.menu-level').hide();
        $(this).next('.menu-level').show();
        $(this).addClass('is-active');
        $(this).text('-');
    }
});

//added the gray overlay outside menu, for mobile
$(function () {
    $('#nav-menu-mobile-button').on('change', function () {
        $(['#js_main-nav-menu', 'body']).toggleClass('is-active');
        var docHeight = $(document).height();
        $("#overlay")
            .height(docHeight - 70)
            .css({
                'margin-top': 70
            });
        if ($("#overlay").css('display') == 'none') {
            $("#overlay").css('display', 'block');
        } else {
            $("#overlay").hide();
            $('.top-nav__links-item_tools').find('.menu-level').hide();
            $('.top-nav__links-item_tools').find('.top-nav__expand').text('+');
        }
    });
    $('#overlay').on('click', function () {
        $("#overlay").css('display', 'none');
        $('#nav-menu-mobile-button').prop('checked', false);
        $(['#js_main-nav-menu', 'body']).toggleClass('is-active');
        $('.top-nav__links-item_tools').find('.menu-level').hide();
        $('.top-nav__links-item_tools').find('.top-nav__expand').text('+');
    });
});

// Switch instruments-and-tools tab after link opening
{
    if (window.location.hash === '#panel2-label') {
        document.querySelector('#panel2-label').click();
    }
}

// Scroll event
{
    let blocks = document.querySelectorAll('.js_sw-stages__block');

    if (blocks[0]) {
        let blockFade = () => {
            let wHeight = window.pageYOffset + window.innerHeight * 0.8;
            /*
                        for (let i = blocks.length; i--;) { //NOTE: May be problems in IE
                            let block = blocks[i];
                            if (wHeight > block.offsetTop) {
                                block.classList.add('is-active');
                            }
                        }
            */

            for (var i = blocks.length - 1; i >= 0; i--) {
                var block = blocks[i];
                if (wHeight > block.offsetTop) {
                    block.classList.add('is-active');
                }
            }

            if (wHeight > blocks[blocks.length - 1].offsetTop) {
                window.removeEventListener('scroll', blockFade);
            }
        };
        blockFade();

        window.addEventListener('scroll', blockFade);
    }
}

// Form focus

(function () {
    let formInputs = document.querySelectorAll('.fbk-form__field-input');

    for (let i = formInputs.length; i--;) {
        formInputs[i].addEventListener('change', activateInput);
    }

    function activateInput(e) {
        if (e.target.value) {
            e.target.classList.add('is-active');
        } else {
            e.target.classList.remove('is-active');
        }
    }
})();

// Resizable block toggle
(() => {
    let blocks = document.querySelectorAll('.js_resizable-block');
    if (!!blocks[0]) {
        let resizeBtns = [];
        for (let i = blocks.length; i--;) {
            let block = blocks[i];
            let resizeBtn = block.querySelector('.js_resizable-block__button');

            resizeBtns.push(resizeBtn);
        }

        for (let i = resizeBtns.length; i--;) {


            resizeBtns[i].onclick = (e) => {
                e.preventDefault();
                if (e.target.classList.contains('is-active')) {
                    e.target.classList.remove('is-active');
                    blocks[resizeBtns.length - i - 1].classList.remove('is-active');
                } else {
                    resetClass(blocks, resizeBtns);

                    e.target.classList.add('is-active');
                    blocks[resizeBtns.length - i - 1].classList.add('is-active');
                }
            };
        }
    }

    function resetClass() {
        for (let i = arguments.length; i--;) {
            for (let j = arguments[i].length; j--;) {
                arguments[i][j].classList.remove('is-active');
            }
        }
    }

})();

// Scroll-to function
$('.js_scroll-to').on('click', function (e) {
    e.preventDefault();

    if (e.target.classList.contains('js_infoblock__links-link')) {
        var parent = e.target;

        while (!parent.classList.contains('js_resizable-block')) {
            parent = parent.parentNode;

            if (parent.tagName == 'body') {
                console.error('No such Node');
                break;
            }

        }

        if (parent.classList.contains('js_resizable-block')) {
            if (!parent.querySelector('.js_resizable-block__button').classList.contains('is-active')) {
                parent.querySelector('.js_resizable-block__button').click();
            }
        }
    }
    $('html, body').animate({
        scrollTop: $(this.hash).offset().top - 30
    }, 1500);
});


// Slider on About Us page (Slick Slider)
$(() => {
    var slider = $('.js_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplay: true,
        asNavFor: '.js_slider-nav'
    });
    $('.js_slider-nav').slick({
        slidesToShow: 7,
        asNavFor: '.js_slider',
        focusOnSelect: true
    });
    // $('.js_slider').on('afterChange', videoPlay);
    // $('.js_slider').on('beforeChange', (event, slick, currentSlide) => {
    //     videoStop(null, null, currentSlide);
    //     slider.slick('slickPlay');
    // });

    function toggleSliderNav(state) {
        var sliderNav = document.querySelector('.js_slider-nav');
        if (state === 'show') {
            sliderNav.style.opacity = '1';
            sliderNav.style.visibility = 'visible';
        } else if (state === 'hide') {
            sliderNav.style.opacity = '0';
            sliderNav.style.visibility = 'hidden';
        } else {
            sliderNav.style.opacity = +sliderNav.style.opacity ? '0' : '1';
        }
    }

    function videoPlay(_event, _slick, currentSlide) {
        let video = getVideo(currentSlide);
        if (!video) {
            return;
        }
        if (video.parentNode.classList.contains('slick-current')) {
            video.play();
            video.addEventListener('ended', onVideoEnded);
            toggleSliderNav('hide');
            slider.slick('slickPause');
        }
    }
    // videoPlay(null, null, 0);

    function onVideoEnded(event) {
        toggleSliderNav('show');
        slider.slick('slickPlay');
    }

    function videoStop(_event, _slick, currentSlide) {
        let video = getVideo(currentSlide);
        if (!video) {
            return;
        }
        // Remove event handler after once work done
        video.removeEventListener('ended', onVideoEnded);
        if (video.parentNode.classList.contains('slick-current')) {
            video.pause();
            video.currentTime = 0;
        }
    }

    function getVideo(slide) {
        let videos = document.querySelectorAll('.js_slider__slide');
        if (videos[0]) {
            return videos[slide].querySelector('.video');
        }
    }

    function contentResize() {
        let contentParents = document.querySelectorAll('.js_slider__slide, .js_slider-nav__item');
        for (let i = contentParents.length; i--;) {
            let contentParent = contentParents[i];
            let content = contentParent.firstElementChild;
            if (!content) {
                console.warn(content);
                return;
            }
            if (content.offsetHeight < content.parentNode.offsetHeight) {
                content.style.width = 'auto';
                content.style.height = '100%';
            } else {
                content.style.width = '';
                content.style.height = '';
            }
        }
    }
    contentResize();
    window.addEventListener('resize', contentResize);

    // VideoJS 

    try {
        var video = videojs('#video');
        
        video.on('pause', function (e) {
            toggleSliderNav('show');
            slider.slick('slickPlay');
        });

        video.on('play', function (e) {
            toggleSliderNav('hide');
            slider.slick('slickPause');
        });
    } catch (error) {
        console.log('There is no video on the page.');
    }

    // Video controls on slide
    /*var video = document.querySelector('.js_slider__slide video');
    if (!video) return; // go out when there is no video
    var progress = document.querySelector('#progress');
    var playPauseBtn = document.querySelector('#playpause');
    var muteBtn = document.querySelector('#mute');
    var fullScreenBtn = document.querySelector('#fs');*/

    /*playPauseBtn.addEventListener('click', function () {
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    });

    video.addEventListener('play', function (e) {
        playToggle();
        toggleSliderNav('hide');
        slider.slick('slickPause');
    });

    video.addEventListener('pause', function (e) {
        playToggle();
        toggleSliderNav('show');
        slider.slick('slickPlay');
    });

    muteBtn.addEventListener('click', function (e) {
        if (video.muted) {
            this.querySelector('.fa').classList.add('fa-volume-up');
            this.querySelector('.fa').classList.remove('fa-volume-off');
        } else {
            this.querySelector('.fa').classList.remove('fa-volume-up');
            this.querySelector('.fa').classList.add('fa-volume-off');
        }
        video.muted = !video.muted;
    });

    fullScreenBtn.addEventListener('click', function (e) {
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else
        if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else
        if (video.webkitEnterFullScreen) {
            video.webkitEnterFullScreen();
        } else
        if (video.msRequestFullscreen) {
            video.msRequestFullscreen();
        }
    });

    video.addEventListener('loadedmetadata', function () {
        progress.setAttribute('max', video.duration);
    });

    video.addEventListener('timeupdate', function () {
        // For mobile browsers, ensure that the progress element's max attribute is set
        if (!progress.getAttribute('max')) progress.setAttribute('max', video.duration);
        progress.value = video.currentTime;
    });

    progress.addEventListener('click', function (e) {
        var pos = (e.pageX - this.getBoundingClientRect().left) / this.offsetWidth;
        video.currentTime = pos * video.duration;
    });

    function playToggle(e) {
        if (video.paused) {
            document.querySelector('.fa').classList.add('fa-play');
            document.querySelector('.fa').classList.remove('fa-pause');
        } else {
            document.querySelector('.fa').classList.remove('fa-play');
            document.querySelector('.fa').classList.add('fa-pause');

        }
    }*/
});

// Split list into columns
$(() => {
    let els = document.querySelectorAll('.js_team__person'); // Elements selector
    if (!els[0]) {
        return;
    }
    let elsParent = els[0].parentNode,
        elsLength = els.length,
        columnsNum = 2, // Number of columns
        columnsTag = 'div', // TagName of column
        columnsClass = 'ab_team__grid-block', // ClassName of column
        columns = [];

    for (let i = 0; i < columnsNum; i++) {
        let add = 0;
        columns[i] = {};

        if (elsLength % columnsNum > i) {
            add = 1;
        }

        if (i === 0) {
            columns[i].start = 0;
        } else {
            columns[i].start = columns[i - 1].end + 1;
        }

        columns[i].length = Math.floor(elsLength / columnsNum) + add;
        columns[i].end = columns[i].start + columns[i].length - 1;

        wrapColumnElements(columns[i], els, columnsTag, columnsClass);
    }

    function wrapColumnElements(column, els, tag, className) {
        let wraper = document.createElement(tag);

        wraper.className = className;

        for (let i = column.start; i <= column.end; i++) {
            wraper.appendChild(els[i]);
        }

        elsParent.appendChild(wraper);
    }
});

// Toggle collaps and Scroll to person on about page
$('body').on('click', '.js_team__button', function (e) {
    var $this             = $(this),
        $buttons          = $('.js_team__button').not(this),
        $personCurrent    = $this.parents('.js_team__person'),
        $personsBlock     = $('.js_team__persons'),
        $resizableCurrent = $this.prev('.js_person__resize-wrap'),
        $resizables       = $personsBlock.find('.js_person__resize-wrap').not($resizableCurrent[0]);
    
    $buttons.removeClass('is-active');
    $this.toggleClass('is-active');
    $resizables.slideUp('fast').promise().then(function () {
        $('html, body').animate({
            scrollTop: $personCurrent.offset().top
        }, 'fast');
    });
    $resizableCurrent.slideToggle('fast');
});

// Collaps open person when click on body
$(document).mouseup((e) => {
    var $container = $('.js_team__persons'),
        target     = e.target;

    if (!$container.has(target).length) {
        $('.js_team__button').removeClass('is-active');
        $container.find('.js_person__resize-wrap').slideUp();
    }
});

/**
 * Make blocks contacts and feedback even 
 */
$(function () {
    var $contacts = $('.ct_contacts');
    if (!$contacts.length) return;

    var $contactBlocks = $('.ct_contacts__block'),
        $feedback      = $('.ct_feedback'),
        contactsHeight = $contacts.outerHeight(),
        feedbackHeight = $feedback.outerHeight();

    if (contactsHeight === feedbackHeight) {
        return;
    } else if (contactsHeight > feedbackHeight) {
        $feedback.css('height', contactsHeight + 'px');
    } else {
        var diff = (feedbackHeight - contactsHeight) / $contactBlocks.length;
        $contactBlocks.each(function () {
            var $this  = $(this),
                height = $this.outerHeight();
            $this.css('height', height + diff + 'px');
        });
    }
});

// change measure system for simple instrument page
$('.measure-system__item').click(function (e) {
    $('.measure-system__item').removeClass('measure-system__item_active');
    $(this).addClass('measure-system__item_active');
    if ($(this).hasClass('metrical')) {
        $('.inst-specs-table__value_metrical').css('display', 'inline-block');
        $('.inst-specs-table__value_imperical').hide();
    } else {
        $('.inst-specs-table__value_metrical').hide();
        $('.inst-specs-table__value_imperical').css('display', 'inline-block');
    }
});

// change btn text for simple instrument page
$('.js_inst-specs__btn').click(function (e) {
    var less = document.getElementsByClassName('show-less')[0].innerHTML;
    var more = document.getElementsByClassName('show-more')[0].innerHTML;
    if ($(this).hasClass('is-active')) {
        document.getElementsByClassName('js_inst-specs__btn')[0].innerHTML = less;
    } else {
        document.getElementsByClassName('js_inst-specs__btn')[0].innerHTML = more;
    }
});

//slider+nav on tool page
$(function () {
    $('.inst-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: false,
        asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
        slidesToShow: 3,
        asNavFor: '.inst-slider',
        focusOnSelect: true,
        infinite: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-arrow-circle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-arrow-circle-right"></i></button>',
        // variableWidth: true
    });
});




/*$('.slider-nav div').each(function (i) {
    $('.slider-nav .slide-' + i + ' a').click(function () {
        $('.inst-slider').slick('slickGoTo', i);
    })
});*/

// Remove Engines in compare table
$('body').on('click', '.js_remove_engine', function (e) {
    e.preventDefault();
    var $this = $(this);
    var toolId = $this.data('tool-id');
    deleteFromCompare(toolId);
});


/**
 * Return array engine from localStorage variable
 */
function getCompare() {
    return localStorage.getItem('compare') ? JSON.parse(localStorage.getItem('compare')) : [];
}

/**
 * Add new engine item to localStorage variable
 * @param {number} engineId - ID of engine in database 
 */
function addToCompare(engineId, engineName) {
    var double = false;
    var maxEngines = 3;
    var enginesArray = getCompare();
    if (enginesArray.length === maxEngines) return;
    // Check if there is the same item 
    enginesArray.forEach(function (item, i) {
        if (item.id === engineId) double = true;
    });
    if (double) return;
    enginesArray.push({
        id: engineId,
        name: engineName
    });
    localStorage.setItem('compare', JSON.stringify(enginesArray));
    // $('body').trigger('addedNewEngine');
    $('body').trigger('engineChanged');
}

/**
 * Erase localStorage engines variable, just remove this one 
 */
function eraseCompare() {
    try {
        localStorage.removeItem('compare');
        $('body').trigger('engineChanged');
    } catch (error) {
        console.error(error);
    }
}

/**
 * Remove engine with ID engineId 
 * @param {number} engineId - ID of engine in database 
 */
function deleteFromCompare(engineId) {
    var enginesArray = getCompare();
    var index;

    enginesArray.forEach(function (item, i) {
        if (item.id === engineId) index = i;
    });

    enginesArray.splice(index, 1);
    console.log(enginesArray.length);
    localStorage.setItem('compare', JSON.stringify(enginesArray));
    $('body').trigger('engineChanged');
}

function goToCompare() {
    var lang = $('html').attr('lang');
    var engines = getCompare().map(function (engine) {
        return engine.id;
    });

    var url = '/' + lang + '/compare?engines=' + engines.toString();
    window.location = url;
}

// Add New Engine to compare array
$('body').on('click', '.js_add-to-compare', function (e) {
    e.preventDefault();
    var $this = $(this);
    var enginesArray = getCompare();
    var engineId = $this.data('tool-id');
    var engineName = $this.data('tool-name');
    if (enginesArray.length < 3) {
        $this.addClass('selected');
    }
    addToCompare(engineId, engineName);
});

// Remove item from compare array
$('body').on('click', '.js_close-compare', function (e) {
    e.stopPropagation();
    e.preventDefault();
    eraseCompare();
    $('.compare-btn-main').hide();
});

// Check if there is any engine in compare array and show compare button if at least one presents
$(function () {
    var enginesArray = getCompare();
    var selectedEnginesSection = $('.selected-engines-wrap');
    var compareEngineTable = $('.compare-engines-table');
    var compareBtnMain = $('.compare-btn-main');
    if (enginesArray.length) {
        if (selectedEnginesSection.length) {
            $('body').trigger('engineChanged');
        } else if (compareEngineTable.length) {
            processSelectEngElem();
            compareBtnMain.hide();
        } else {
            compareBtnMain.show();
        }
    }
});

// Close section with engines selected to compare 
$('body').on('click', '.js_selected-engines-close', function (e) {
    eraseCompare();
    $('.selected-engines-wrap').hide();
});

/**
 * Process section with engines selected to compare
 * @param {*} selectedEnginesSection - element with class="selected-engines-wrap"
 */
function processSelectedEnginesSection(selectedEnginesSection) {
    var enginesArray = getCompare();
    var addEngineBtn = $('.inst-compare');
    $('[data-tool-id]').removeClass('selected');

    enginesArray.forEach(function (engine) {
        var id = engine.id;
        if (id === 'separator') return;
        $('[data-tool-id="' + id + '"]').addClass('selected');
    });

    $('.selected-item').each(function (i) {
        var $this = $(this);
        var $text = $this.find('.selected-item__text');
        var $delete = $this.find('.selected-item__btn-delete');
        var $selectElement = $this.find('.select-engine');
        var engine = enginesArray[i];
        if (engine) {
            $text.text(engine.name).removeClass('text_normal');
            $delete.data('tool-id', engine.id).removeClass('hide');
            $selectElement.hide();
            $this.show();
        } else {
            $text.text($text.data('default-text')).addClass('text_normal');
            $delete.addClass('hide').data('tool-id', '');
            // Hide last not used item
            if (i !== enginesArray.length) {
                $this.hide();
            } else {
                $selectElement.show();
                $this.show();
            }
        }
    });

    if (enginesArray.length > 0) {
        selectedEnginesSection.show();
    } else {
        selectedEnginesSection.hide();
    }

    if (addEngineBtn.length) {
        addEngineBtn.show();
        enginesArray.forEach(function (engine) {
            if (engine.id === addEngineBtn.data('tool-id')) {
                addEngineBtn.hide();
            }
        });
    }
}

// Event handler for 'engineChanged' event
$('body').on('engineChanged', function (e) {
    var $selectedEnginesSection = $('.selected-engines-wrap');
    var $enginesTable = $('.compare-engines-table');
    if ($selectedEnginesSection.length) {
        processSelectedEnginesSection($selectedEnginesSection);
    }
    if ($enginesTable.length) {
        goToCompare();
    }
});


/**
 * Process select engine element and mark 'selected' class if the one is selected
 */
function processSelectEngElem() {
    var $selectEngineElem = $('.select-engine');
    if ($selectEngineElem.length) {
        var enginesArray = getCompare();
        enginesArray.forEach(function (engine) {
            var id = engine.id;
            if (id === 'separator') return;
            $selectEngineElem.find('[data-tool-id="' + id + '"]').addClass('selected');
        });
    }
}


$('body').on('click', '.selected-item__btn-delete', function (e) {
    var id = $(this).data('tool-id');
    deleteFromCompare(id);
});

$('body').on('click', '.js_go-to-compare', function (e) {
    e.preventDefault();
    goToCompare();
});

// Select Engines Element
$(function () {
    var selectEngine = $('.select-engine');
    if (selectEngine.length) {
        var mainLabel = $('.select-engine__list-main');
        var label = selectEngine.find('.select-engine__label');
        var li = $('.js_select-engine-li');
        label.on('click', function (e) {
            $(this).siblings('ul').slideToggle('fast');
        });
        li.on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            var id = $this.data('tool-id');
            if (id === 'separator') return;
            var name = $this.text();
            $('.select-engine__list-main').slideUp('fast');
            addToCompare(id, name);
        });
        // Use Custom scroll plugin
        mainLabel.mCustomScrollbar({
            theme: 'minimal-dark'
        });

        $(document).mouseup(function (e) {
            if (!selectEngine.has(e.target).length) {
                label.each(function () {
                    $(this).siblings('ul').slideUp('fast');
                });
            }
        });
    }
});

// Event handler for print button
$('body').on('click', '.print', function (e) {
    e.preventDefault();
    window.print();
});

$(function () {
    setRightPosition();
    setBottomPosition();
    window.onresize = setRightPosition;
    window.onscroll = setBottomPosition;
});

function setRightPosition() {
    var main = document.querySelector('main');
    var btn = document.querySelector('.compare-btn-main');
    var mainRight = main.getBoundingClientRect().right;
    var width = document.documentElement.clientWidth
    var right = mainRight >= width ? 20 : width - mainRight;
    btn.style.right = right + 'px';
}

function setBottomPosition() {
    var btn = document.querySelector('.compare-btn-main');
    var footer = document.querySelector('.footer').getBoundingClientRect();
    var wHeight = window.innerHeight;
    var diff = wHeight - footer.top;
    if (diff > 0) {
        btn.style.bottom = diff + 20 + 'px';
    } else {
        btn.style.bottom = '20px';
    }
}

// The 'Custom Solution' for short spindel separator
$(function () {
    var $column = $('.engines-column').eq(2);
    if(!$column.length) return;
    var $menuSeparator = $('.menu-item__separator .menu-item__text');
    var $items = $column.find('.engines-column-item');
    $items.eq(6).after($items.eq(0).clone().html($menuSeparator.html()));
});